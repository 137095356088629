
  import TabItem from  '@/components/TabItem/TabItem.vue';
  import StationRouter from '@/components/StationRouter/index.vue'
  import { defineComponent, ref } from 'vue';
import { ar } from 'element-plus/es/locale';
  export default defineComponent({
    name: 'TopOne',
    props: [
      'data'
    ],
    components: {
      TabItem,
      StationRouter
    },
    data() {
      return {
        tabValue: '',
        data1: ref([["兰州","阿拉山口","莫斯科"], ["兰州","霍尔果斯","杜伊斯堡"], ["兰州","包头","二连浩特","乌兰巴托","伊尔库茨克","欧洲"]]),
        data2: ref([["兰州","阿拉山口","阿拉木图"], ["兰州","霍尔果斯","德黑兰"], ["兰州","喀什","伊尔克什坦","奥什","塔什干"]]),
        data3: ref([["兰州","日喀则","吉隆","加德满都"], ["兰州","喀什","红其拉甫","伊斯兰堡"], ["兰州","成都","昆明","柬埔寨"]]),
        data4: ref([["兰州","成都","昆明","河内"], ["兰州","成都","仰光"], ["兰州","成都","昆明","柬埔寨"]]),
      }
    },
    mounted() {
      this.tabValue = this.data[0].trainsName;
    },
    methods: {
      handleChange(val: string, index: number) {
        this.tabValue = val;
        this.$emit('change', index)
      }
    }
  })
