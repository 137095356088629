import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/Home/Home.vue'
import MiniView from '../views/Mini/Mini.vue'
import MidView from '../views/Mid/Mid.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/mid',
        name: 'mid',
        component: MidView,
      },
      {
        path: '/mini',
        name: 'mini',
        component: MiniView,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
