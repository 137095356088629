
import { defineComponent } from 'vue';
import Header from '@/components/Header/Header.vue';
import Map from '../Home/components/Map/Map.vue';
import About from '../Home/components/About/About.vue';
import Business from '../Home/components/Business/Business.vue';
import Advantage from '../Home/components/Advantage/Advantage.vue';
import Goods from '../Home/components/Goods/Goods.vue';
import Client from '../Home/components/Client/Client.vue';
import BlockTrain from '../Home/components/BlockTrain/BlockTrain.vue';
import Train from '../Home/components/Train/Train.vue';
import CargoFlight from '../Home/components/CargoFlight/CargoFlight.vue';
import TrainInformation from '../Home/components/TrainInformation/TrainInformation.vue';
import CustomsClearance from '../Home/components/CustomsClearance/CustomsClearance.vue';

export default defineComponent({
  name: 'BigScreen',
  components: {
    Header,
    Map,
    About,
    Business,
    Advantage,
    Goods,
    Client,
    BlockTrain,
    Train,
    CargoFlight,
    TrainInformation,
    CustomsClearance,
  },
  data() {
    return {
      width: 9600,
      height: 2700,
      scale: '1',
    };
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    /**
     * 重置缩放比例
     */
    resize() {
      const { innerWidth, innerHeight } = window;
      const { width, height } = this;
      const scaleX = innerWidth / width;
      const scaleY = innerHeight / height;
      this.scale = `${scaleX},${scaleY}`;
    },
  },
});
