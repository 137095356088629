import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-333abd7f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topTow-layer1" }
const _hoisted_2 = {
  class: "topTow-layer2",
  ref: "elRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabItem = _resolveComponent("TabItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TabItem, {
        val: _ctx.tabValue,
        onChoosed: _ctx.tabChoosed
      }, {
        default: _withCtx(() => [
          _createTextVNode("集装箱装卸量")
        ]),
        _: 1
      }, 8, ["val", "onChoosed"]),
      _createVNode(_component_TabItem, {
        val: _ctx.tabValue,
        onChoosed: _ctx.tabChoosed
      }, {
        default: _withCtx(() => [
          _createTextVNode("件杂货操作量")
        ]),
        _: 1
      }, 8, ["val", "onChoosed"])
    ]),
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 64))
}