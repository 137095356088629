import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "train-information" }
const _hoisted_2 = {
  class: "train-info-list",
  style: {"height":"787px","margin":"33px 0 0 0"}
}

export function render(_ctx, _cache) {
  const _component_MiniTitle = _resolveComponent("MiniTitle")
  const _component_MoveTable = _resolveComponent("MoveTable")
  const _component_item = _resolveComponent("item")

  return (_openBlock(), _createBlock(_component_item, { title: "火车到发信息" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MiniTitle, { title: "在途数组" }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.data.length > 0)
            ? (_openBlock(), _createBlock(_component_MoveTable, {
                key: 0,
                style: {"height":"787px"},
                flex: [1,1,1,1,1],
                title: _ctx.title,
                data: _ctx.data
              }, null, 8, ["title", "data"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}