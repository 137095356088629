
import { defineComponent } from "vue";
import ImgItem from './ImgItem.vue'
export default defineComponent({
  name: 'TopThree',
  components: {
    ImgItem
  },
  props: [
    'data'
  ],
})
