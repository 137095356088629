import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  class: "col",
  style: {"height":"1400px"}
}
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  class: "col",
  style: {"height":"1230px"}
}
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "row middle" }
const _hoisted_11 = {
  class: "col",
  style: {"height":"100%"}
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  class: "col",
  style: {"height":"1300px"}
}
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = {
  class: "col",
  style: {"height":"1300px"}
}
const _hoisted_19 = { class: "col" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_About = _resolveComponent("About")
  const _component_Business = _resolveComponent("Business")
  const _component_Advantage = _resolveComponent("Advantage")
  const _component_Goods = _resolveComponent("Goods")
  const _component_Client = _resolveComponent("Client")
  const _component_Map = _resolveComponent("Map")
  const _component_BlockTrain = _resolveComponent("BlockTrain")
  const _component_Train = _resolveComponent("Train")
  const _component_CargoFlight = _resolveComponent("CargoFlight")
  const _component_TrainInformation = _resolveComponent("TrainInformation")
  const _component_CustomsClearance = _resolveComponent("CustomsClearance")

  return (_openBlock(), _createElementBlock("div", {
    class: "big-screen mid",
    style: _normalizeStyle({transform: 'scale(' +_ctx.scale+')', width: _ctx.width + 'px', height: _ctx.height + 'px',})
  }, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_About)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Business)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Advantage)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Goods)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Client)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Map)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_BlockTrain)
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Train)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_CargoFlight)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_TrainInformation)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_CustomsClearance)
        ])
      ])
    ])
  ], 4))
}