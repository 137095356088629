import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "train" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MiniTitle = _resolveComponent("MiniTitle")!
  const _component_TrainCount = _resolveComponent("TrainCount")!
  const _component_item = _resolveComponent("item")!

  return (_openBlock(), _createBlock(_component_item, { title: "火车总装卸量" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MiniTitle, { title: "装卸火车（吨）" }),
        _createVNode(_component_TrainCount, {
          loadValue: _ctx.trainData.cargoLoadNum,
          unit: "吨",
          unloadValue: _ctx.trainData.cargoUnloadNum
        }, null, 8, ["loadValue", "unloadValue"]),
        _createVNode(_component_MiniTitle, { title: "装卸火车（车）" }),
        _createVNode(_component_TrainCount, {
          loadValue: _ctx.trainData.teuLoadnum,
          unit: "车",
          unloadValue: _ctx.trainData.teuUnloadNum
        }, null, 8, ["loadValue", "unloadValue"])
      ])
    ]),
    _: 1
  }))
}