
import request from '@/utils/request'
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import MoveTable from '@/components/MoveTable/MoveTable.vue';

export default defineComponent({
  name: 'HomeTrainInformation',
  components: {
    Item,
    MiniTitle,
    MoveTable,
  },
  data() {
    return {
      title: {
        trainCod: '序号',
        upDown: '类型',
        strainName: '起点站',
        rtrainName: '经过站',
        etrainName: '终点站',
      },
      data: [],
    };
  },
  mounted() {
    request({
      url: '/rest/screen/getApiLgyTrainsTrackList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.data = res.data;
      }
    })
  }
});
