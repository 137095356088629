<template>
  <div class="large">
    <div class="middle">
      <!-- <div class=""></div>
      <div class=""></div>
      <div class=""></div> -->
      <div class="small"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.large {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 0.5px solid #0086CD;
  display: flex;
  justify-content: center;
  align-items: center;
  .middle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 0.5px solid #0086CD;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .small {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: linear-gradient(180deg, #00BBE8 0%, #0086CD 100%);
    }
  }
}
</style>