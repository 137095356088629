import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36d4bc88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TopOne" }
const _hoisted_2 = { class: "layer1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabItem = _resolveComponent("TabItem")!
  const _component_StationRouter = _resolveComponent("StationRouter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createBlock(_component_TabItem, {
          val: _ctx.tabValue,
          onChoosed: ($event: any) => (_ctx.handleChange($event, index)),
          key: item.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.trainsName), 1)
          ]),
          _: 2
        }, 1032, ["val", "onChoosed"]))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "layer2",
        key: item.id + 'layer2'
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.transRolad, (titem) => {
          return _withDirectives((_openBlock(), _createBlock(_component_StationRouter, {
            value: titem,
            key: titem + 'StationRouter'
          }, null, 8, ["value"])), [
            [_vShow, _ctx.tabValue == item.trainsName]
          ])
        }), 128))
      ]))
    }), 128))
  ]))
}