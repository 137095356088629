
import request from '@/utils/request'
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import TrainCount from './components/TrainCount.vue'
export default defineComponent({
  name: 'HomeTrain',
  components: {
    Item,
    MiniTitle,
    TrainCount
  },
  data() {
    return {
      trainData: {
        cargoLoadNum: 0,
        cargoUnloadNum: 0,
        teuLoadnum: 0,
        teuUnloadNum: 0,
      }
    };
  },
  mounted() {
    request({
      url: '/rest/screen/getApiLgyTrainsLoadList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.trainData = res.data[0];
      }
    })
  },
});
