<template src="./Mini.html"></template>
<style src="./Mini.scss" lang="scss"></style>

<script>
import Header from '@/components/Header/Header.vue';
import Map from '../Home/components/Map/Map.vue';
import About from '../Home/components/About/About.vue';
import Business from '../Home/components/Business/Business.vue';
import Advantage from '../Home/components/Advantage/Advantage.vue';
import Goods from '../Home/components/Goods/Goods.vue';
import Client from '../Home/components/Client/Client.vue';
import BlockTrain from '../Home/components/BlockTrain/BlockTrain.vue';
import Train from '../Home/components/Train/Train.vue';
import CargoFlight from '../Home/components/CargoFlight/CargoFlight.vue';
import TrainInformation from '../Home/components/TrainInformation/TrainInformation.vue';
import CustomsClearance from '../Home/components/CustomsClearance/CustomsClearance.vue';

export default({
  name: 'BigScreen',
  components: {
    Header,
    Map,
    About,
    Business,
    Advantage,
    Goods,
    Client,
    BlockTrain,
    Train,
    CargoFlight,
    TrainInformation,
    CustomsClearance,
  },
  data() {
    return {
      width: 1024,
      height: 768,
      scale: '1',
      scaleLevel: '1',
      showLeft: false,
      showRight: false,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      this.scale = String((document.body.clientWidth - 20) / 2431);
      this.scaleLevel = String((document.body.clientWidth - 20) / 3270);
    },

    handleHeaderChange(val) {
      switch(val) {
        case 0:
          this.showLeft = false;
          this.showRight = false;
          break;
        case 1:
          this.showLeft = true;
          this.showRight = false;
          break;
        case 2:
          this.showLeft = false;
          this.showRight = true;
          break;
        default:
          this.showLeft = false;
      }
    }
  },
});
</script>