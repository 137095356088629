
import { defineComponent } from "vue";

export default defineComponent({
  name: 'ImgItem',
  props: {
    type: {
      type: Number,
      default: ''
    },
    value: {
      type: String,
      default: '63,935'
    }
  },
})
