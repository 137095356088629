
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HomeCargoFlightLeft',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      randomArr: <any>[]
    }
  },
  watch: {
    data() {
      this.createdBalls();
    }
  },
  methods: {
    createdBalls() {
      let count = this.data.length;
      const showHeight = (document.getElementById('cargoBottomLeft') as any).clientHeight;   //限制钻石生成区域高度
      const showWidth = (document.getElementById('cargoBottomLeft') as any).clientWidth;
      let max = 0;
      let style = 0;
      while (count > 0) {
        let row = Math.floor(Math.random() * showHeight);
        let col = Math.floor(Math.random() * showWidth);
        if ( this.randomArr.length === 0) {
          style++;
          this.randomArr.push({
            left: col,
            top: row,
            title: this.data[count - 1],
            style
          })
          count -= 1
        } else {
          const noOverlap =  this.randomArr.every((v: { left: number; top: number; }) => Math.hypot(v.left - col, v.top - row) > 60)
          if (noOverlap) {
            style++;
            this.randomArr.push({
              left: col,
              top: row,
              title: this.data[count - 1],
              style
            })
            count -= 1
          }
        }
        if (style === 10) {
          style = 0;
        }
        max++;
        if (max > 10000) {
          count = 0;
        }
      }
    }
  },
})
