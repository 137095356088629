import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f445a24e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cargo-bottom-left" }
const _hoisted_2 = {
  class: "cargo-bottom-left-main",
  id: "cargoBottomLeft"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.randomArr, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'cargo-bottom-left' + index,
          style: _normalizeStyle({left: item.left + 'px', top: item.top + 'px'}),
          class: _normalizeClass('s' + item.style)
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(item.title), 1)
          ])
        ], 6))
      }), 128))
    ])
  ]))
}