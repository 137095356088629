
import request from '@/utils/request'
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import TopOne from './components/TopOne.vue'
import TopTwo from './components/TopTwo.vue'
import TopThree from './components/TopThree.vue'
export default defineComponent({
  name: 'HomeBlockTrain',
  components: {
    Item,
    MiniTitle,
    TopOne,
    TopTwo,
    TopThree
  },
  data() {
    return {
      data: {},
      hcData: {},
      cData: null,
    };
  },
  mounted() {
    // 获取陆港介绍信息以及贸易通道信息
    request({
      url: '/rest/screen/getApiLgyGjblIsoList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        let data = res.data;
        data.forEach((item: any) => {
          let transRolad = JSON.parse(item.transRolad);
          transRolad.forEach((sitem: any, sindex: number) => {
            transRolad[sindex] = sitem.split('，');
          })
          item.transRolad = transRolad;
        })
        this.data = data;
        this.handleTabChange(0);
      }
    })
  },
  methods: {
    /**
     * 监听tab切换
     * @param {*} index 
     */
    handleTabChange(index: number) {
      if (!(this.data as any)[index]) {
        return;
      }
      let handCargo = (this.data as any)[index].lgyHandCargoList;
      handCargo.forEach((item: any) => {
        item.value = parseInt(item.month.replace(/-/g, ''));
      })
      handCargo = handCargo.sort((a: any, b: any) => {
        return a.value - b.value;
      })
      let data: any = {
        '集装箱装卸量': [],
        '件杂货操作量': []
      };
      let time: any = [];
      handCargo.forEach((item: any) => {
        time.push(item.month)
        data['集装箱装卸量'].push(item.teuNum);
        data['件杂货操作量'].push(item.cargoNum);
      })
      this.hcData = {
        time,
        data
      };
      (this.cData as any) = {
        cargoLoadNum: (this.data as any)[index].cargoLoadNum,
        cargoUnloadNum: (this.data as any)[index].cargoUnloadNum,
      }
    }
  }
});
