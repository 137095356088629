import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleClick(0)))
    }, "兰州国际港务区生产调度中心"),
    _createElementVNode("div", {
      class: "l",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.handleClick(1)))
    }),
    _createElementVNode("div", {
      class: "r",
      onClick: _cache[2] || (_cache[2] = $event => (_ctx.handleClick(2)))
    })
  ]))
}