
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import Circle from './components/Circle/Circle.vue';
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { useState } from '@/hooks/useState'

export default defineComponent({
  name: 'HomeMap',
  components: {
    Item,
    MiniTitle,
    Circle,
    Vue3SeamlessScroll,
  },
  data() {
    return {
      scroll: true,
      introdutionInfo: {},
      listData: []
    };
  },
  watch: {
    introdutionInfo(val) {
      if (val && val.tradeRoutes) {
        this.listData = JSON.parse(val.tradeRoutes)
      }
    }
  },
  mounted() {
    const storeState: any = useState({
      introdutionInfo: (state: any) => state.introdutionInfo
    })
    const { introdutionInfo } = storeState;
    this.introdutionInfo = introdutionInfo;
    

    // (document.getElementById('map') as any).addEventListener('mousemove', (e: any) => {
    //   console.log(e.offsetX - 30, e.offsetY - 30)
    // });
  },
});
