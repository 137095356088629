
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderComponent',
  methods: {
    handleClick(val: number) {
      this.$emit('change', val);
    },
  },
})
