import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "move-table" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "content" }

export function render(_ctx, _cache) {
  const _component_vue3_seamless_scroll = _resolveComponent("vue3-seamless-scroll")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title, (item, key, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item,
            style: _normalizeStyle({flex: _ctx.flex[index]})
          }, _toDisplayString(item), 5))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_vue3_seamless_scroll, {
        class: "seamless-warp",
        list: _ctx.data,
        hover: true,
        limitScrollNum: 3,
        step: 1
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title, (sitem, skey, sindex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: skey + sindex,
                    style: _normalizeStyle({flex: _ctx.flex[sindex]})
                  }, _toDisplayString(item[skey]), 5))
                }), 128))
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["list"])
    ])
  ]))
}