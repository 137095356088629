
import request from '@/utils/request'
import * as echarts from 'echarts';
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import MoveTable from '@/components/MoveTable/MoveTable.vue';

export default defineComponent({
  name: 'HomeClient',
  components: {
    Item,
    MiniTitle,
    MoveTable,
  },
  data() {
    return {
      title: {
        cusCode: '客户代码',
        cusName: '客户名称',
      },
      data: [],
      chartType: '集装箱装卸量',
      value1: 0,
      value2: 0,
      hcTime: [],
      hcData: [],
    };
  },
  methods: {
    chartTypeChanged(val: string) {
      this.chartType = val
      this.getData(this.chartType)
    },
    getData(val: string) {
      let xAxisData = []
      let chartData = []
      xAxisData = this.hcTime
      chartData = this.hcData[this.chartType as any];
      this.drawLine(xAxisData, chartData, val)
    },
    drawLine(xAxisData: Array<string>, chartData: Array<number>, val: string) {
      const option = {
      title: {
        text: val === '集装箱装卸量' ? "单位：TEU" : '单位：吨',
        textStyle: {
          color: '#C4EAFB',
          fontSize: 18,
          fontWeight: 'lighter',
        },
        left: '1.3%',
        top: '5%',
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: "rgba(65, 70, 80, .9)",
        borderColor: "rgba(65, 70, 80, .9)",
        textStyle: {
          color: "rgba(255, 255, 255, .9)",
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#C4EAFB',
          fontSize: 18,
          fontWeight: 'lighter',
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#C4EAFB',
          fontSize: 18,
          fontWeight: 'lighter',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)',
          },
        },
      },
      grid: {
        top: '26%',
        left: '2%',
        right: '3%',
        bottom: '3%',
        containLabel: true,
      },
      series: [
        {
          data: chartData,
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            normal: {
              color: function (params: any) {
                let colorList = [
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#02396B' },
                    { offset: 1, color: '#00BDEA' },
                  ]),
                  new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0, color: '#2D384B' },
                    { offset: 1, color: '#F2DD9F' },
                  ]),
                ];
                return colorList[params.dataIndex];
              },
            },
          },
          label: {
            normal: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                textBorderColor: '#000000'
              },
            }
          },
        },
      ],
    }
      const chartDom: any = document.getElementById('echart-client');
      const myChart = echarts.init(chartDom);
      myChart.setOption(option);
    }
  },
  mounted() {
    request({
      url: '/rest/screen/getApiLgyCusList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.data = res.data.cusCargo;
        let lgyCus = res.data.HandCargo;
        lgyCus.forEach((item: any) => {
          item.value = parseInt(item.month.replace(/-/g, ''));
        })
        lgyCus = lgyCus.sort((a: any, b: any) => {
          return a.value - b.value;
        })
        let hcData: any = {
          '集装箱装卸量': [],
          '件杂货操作量': []
        };
        let hcTime: any = [];
        let value1 = 0;
        let value2 = 0;
        lgyCus.forEach((item: any) => {
          hcTime.push(item.month)
          hcData['集装箱装卸量'].push(item.teuNum);
          hcData['件杂货操作量'].push(item.cargoNum);
          value1 = value1 + Number(item.teuNum);
          value2 = value2 + Number(item.cargoNum);
        })
        this.hcTime = hcTime;
        this.hcData = hcData;
        this.value1 = value1;
        this.value2 = value2;
        this.getData(this.chartType);
        console.log();
      }
    })
  },
});
