<div class="big-screen mini">
  <Header @change="handleHeaderChange"></Header>
  <div class="body main" :class="{hide: showLeft || showRight}">
    <div class="row middle">
      <div class="col" style="width: 2431px; height: 1933px;" :style="{transform: 'scale(' +scale+')'}">
        <Map></Map>
      </div>
    </div>
  </div>
  <div class="body left" :class="{show: showLeft}">
    <div class="container" style="width: 3270px; height: 1933px;" :style="{transform: 'scale(' +scaleLevel+')'}">
      <div class="row">
        <div class="col">
          <About></About>
        </div>
      </div>
      <div class="row">
        <div class="col" style="height: 1320px;">
          <Business></Business>
        </div>
        <div class="col">
          <Advantage></Advantage>
        </div>
      </div>
      <div class="row">
        <div class="col" style="height: 1080px;">
          <Goods></Goods>
        </div>
        <div class="col">
          <Client></Client>
        </div>
      </div>
    </div>
  </div>
  <div class="body right" :class="{show: showRight}">
    <div class="container" style="width: 3270px; height: 1933px;" :style="{transform: 'scale(' +scaleLevel+')'}">
      <div class="row">
        <div class="col">
          <BlockTrain></BlockTrain>
        </div>
      </div>
      <div class="row">
        <div class="col" style="height: 1260px;">
          <Train></Train>
        </div>
        <div class="col">
          <CargoFlight></CargoFlight>
        </div>
      </div>
      <div class="row">
        <div class="col" style="height: 1260px;">
          <TrainInformation></TrainInformation>
        </div>
        <div class="col">
          <CustomsClearance></CustomsClearance>
        </div>
      </div>
    </div>
  </div>
</div>