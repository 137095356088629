import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "advantage" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_item = _resolveComponent("item")

  return (_openBlock(), _createBlock(_component_item, { title: "平台优势" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meritList, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.id
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("span", {
                  style: {"white-space":"pre-wrap"},
                  innerHTML: item.introduction
                }, null, 8, _hoisted_2)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}