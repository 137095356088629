
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TrainCount',
  props: {
    loadValue: {
      type: Number,
      default: 4567891
    },
    unloadValue: {
      type: Number,
      default: 4567891
    },
    unit: {
      type: String,
      default: ''
    }
  },
})
