
import request from '@/utils/request'
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';

export default defineComponent({
  name: 'HomeCustomsClearance',
  components: {
    Item,
    MiniTitle,
  },
  data() {
    return {
      type: '',
      data: [],
    };
  },
  mounted() {
    request({
      url: '/rest/screen/getApiLgyClearanceList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.type = res.data[0].clearanceName;
        let data: any = {};
        res.data.forEach((item: any) => {
          data[item.clearanceName] = item;
        });
        this.data = data;
      }
    })
  }
});
