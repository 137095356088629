
import request from '@/utils/request'
import { defineComponent } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';

export default defineComponent({
  name: 'HomeAdvantage',
  components: {
    Item,
    MiniTitle,
  },
  data() {
    return {
      meritList: []
    };
  },
  mounted() {
    request({
      url: '/rest/screen/getApiMeritList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.meritList = res.data;
      }
    })
  },
});
