
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ItemComponent',
  props: {
    title: String
  },
  data() {
    return {
        flgs: Math.floor(Math.random() * 5) + 's'
    }
  },
  computed: {
    lightStyle () {
      return {
        "--lightflgs": this.flgs,
      }
    }
  },
})
