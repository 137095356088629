
import TabItem from  '@/components/TabItem/TabItem.vue';
import * as echarts from 'echarts';
import { defineComponent, onMounted, Ref, ref, toRefs, watch} from 'vue'
export default defineComponent({
  name: "TopTwo",
  components: {
    TabItem
  },
  props:{
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { data } = toRefs(props)
    const tabValue: Ref<string> = ref('集装箱装卸量')
    const elRef = ref(null);
    let myChart = null;

    onMounted(() => {
      getData(tabValue.value)
    })

    watch(
      () => props.data,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          tabChoosed('集装箱装卸量');
        }
      },
      { deep: true },
    );
      
    function tabChoosed(val: string) {
      tabValue.value = val
      getData(val);
    }

    function getData(val: string) {
      let xAxisData = []
      let chartData = []
      xAxisData = data.value.time;
      chartData = data.value.data[tabValue.value];
      drawLine(xAxisData, chartData, val)
    }

    function drawLine(xAxisData: Array<string>, chartData: Array<number>, val: string) {
      //const legendData = chartData.map((_) => _.name);
      const option = {
        //color,
        title: {
          text: val === '集装箱装卸量' ? "单位：TEU" : '单位：吨',
          left: 20,
          top: 0,
          textStyle: {
            color: "#87BCFF",
            fontSize: "16",
            fontWeight: 600,
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(65, 70, 80, .9)",
          borderColor: "rgba(65, 70, 80, .9)",
          textStyle: {
            color: "rgba(255, 255, 255, .9)",
          },
        },
        legend: {
          icon: "rect",
          top: 15,
          itemWidth: 16,
          itemHeight: 8,
          //data: legendData,
          textStyle: {
            color: "#87BCFF",
            fontSize: "12",
            fontWeight: 400,
          },
        },
        grid: {
          top: 40,
          bottom: "10%",
          left: 50,
          right: 30,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#504C4D",
            },
          },
          axisLabel: {
            color: "#DCDFE7",
            formatter: function(val: string, index: number) {
              
              return `${val.split('-')?.[1]}月`
            }
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#504C4D",
            },
          }
          
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#504C4D",
            },
          },
          axisLabel: {
            color: "#DCDFE7",
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#504C4D",
            },
          },
        },
        series: {
          //name: item.name,
          type: "line",
          lineStyle: 1,
          symbol: "circle",
          symbolSize: 3,
          data: chartData,
          smooth: true,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: 'rgb(1, 191, 236, 0)'
              },
              {
                offset: 1,
                color: 'rgb(128, 255, 165)'
              }
            ])
          }
        },
      };
      myChart = echarts.init(elRef.value);
      myChart.setOption(option);
    }
    return {
      tabValue,
      tabChoosed,
      elRef
    }
  }
})
