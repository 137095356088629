import { createStore } from 'vuex'

export default createStore({
  state: {
    introdutionInfo: {}
  },
  getters: {
    introdutionInfo: state => state.introdutionInfo,
  },
  mutations: {
    SET_INTRODUTION_INFO: (state, data) => {
      state.introdutionInfo = data
    },
  },
  actions: {
    setIntrodutionInfo({ commit }, status) {
      commit('SET_INTRODUTION_INFO', status)
    }
  },
  modules: {
  }
})
