import { computed } from 'vue'
import { mapState, useStore } from 'vuex'
// mapper是存在state中的数据
export function useState(mapper: any) {
  const store = useStore()
  const storeStateFns = mapState(mapper)
  const storeState: any = {}
  Object.keys(storeStateFns).forEach(fnKey => {
    const fn = storeStateFns[fnKey].bind({ $store: store })
    storeState[fnKey] = computed(fn)
  })
  return storeState
}
