
import request from '@/utils/request'
import StationRouter from '@/components/StationRouter/index.vue'
import { defineComponent, ref } from 'vue';
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import Left from './components/Left.vue'
import Right from './components/Right.vue'
export default defineComponent({
  name: 'HomeCargoFlight',
  components: {
    Item,
    MiniTitle,
    StationRouter,
    Left,
    Right
  },
  setup() {
    let data = ref([]);
    let leftData = ref([]);
    let rightData = ref([]);

    request({
      url: '/rest/screen/getApiLgyGjhyList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        let lData = res.data[0];
        data.value = lData.transRolad.split("，")
        leftData.value = lData.goGoods.split("，")
        rightData.value = lData.backGoods.split("，")
      }
    })
    return {
      data,
      leftData,
      rightData
    }
  }
});
