
import { defineComponent } from 'vue';
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";

export default defineComponent({
  name: 'MoveTableComponent',
  components: {
    Vue3SeamlessScroll,
  },
  props: [
    'flex',
    'data',
    'title'
  ],
})
