<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  created() {
    const bodyWidth = document.body.clientWidth;
    if (bodyWidth < 1060) {
      this.$router.push({
        path: '/mini'
      })
    } else if (bodyWidth >= 1060 && bodyWidth < 2000) {
      this.$router.push({
        path: '/mid'
      })
    } else {
      this.$router.push({
        path: '/home'
      })
    }
  }
}
</script>
<style lang="scss">
body{
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
ul{
  margin: 0;
  padding: 0;
  li{
    list-style: none;
  }
}
</style>
