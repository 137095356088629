
import request from '@/utils/request'
import { defineComponent } from 'vue';
import { useState } from '@/hooks/useState'
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import Aura from '@/components/Aura/Aura.vue';
import RotateAnimation from './components/RotateAnimation.vue'
export default defineComponent({
  name: 'HomeBusiness',
  components: {
    Item,
    MiniTitle,
    Aura,
    RotateAnimation
  },
  data() {
    return {
      type: '总收益',
      introdutionInfo: {},
      trafficList: {
        '总收益': {}
      }
    };
  },
  mounted() {
    const storeState: any = useState({
      introdutionInfo: (state: any) => state.introdutionInfo
    })
    const { introdutionInfo } = storeState	
    this.introdutionInfo = introdutionInfo;

    // 获取业务总量数据
    request({
      url: '/rest/screen/getApiLgyTrafficList',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        let types: any = {
          '1': '总业务量',
          '2': '总收益',
          // '3': '总收入',
          '3': '总收入',
        }
        let list: any = {};
        res.data.forEach((item: any) => {
          list[types[item.trafficType]] = item;
        })
        this.trafficList = list;
      }
    })
  },
});
