
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeMapCircle',
  props: [
    'animation'
  ],
  data() {
    return {
      type: 'graph',
    };
  },
  mounted() {
    if (typeof this.animation === 'boolean') {
      if (this.animation) {
        this.type = 'animation'
      } else {
        this.type = 'graph'
      }
    } else {
      this.type = 'animation'
    }
  }
});
