
import request from '@/utils/request'
import { defineComponent } from 'vue';
import { Store, useStore } from 'vuex'
import Item from '@/components/Item/Item.vue';
import MiniTitle from '@/components/MiniTitle/MiniTitle.vue';
import Aura from '@/components/Aura/Aura.vue';
export default defineComponent({
  name: 'HomeAbout',
  components: {
    Item,
    MiniTitle,
    Aura,
  },
  data() {
    return {
      companyInfo: {},
      activeCompany: '兰州陆港物业管理有限公司',
      data: {},
    };
  },
  mounted() {
    const store: Store<any> = useStore();
    // 获取陆港介绍信息以及贸易通道信息
    request({
      url: '/rest/screen/getIntrodutionInfo',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        this.companyInfo = res.data;
        store.dispatch('setIntrodutionInfo', this.companyInfo);
      }
    })
    // 请求平台及公司数据
    request({
      url: '/rest/screen/getPlatformInfo',
      method: 'get',
    }).then((res: any) => {
      if (res.code === 200) {
        let data = res.data;
        let showData: any = {};
        data.forEach((item: any, index: number) => {
          if (index === 0) {
            this.activeCompany = item.companyName;
          }
          showData[item.companyName] = {
            index: index + 1,
            name: item.companyName,
            value1: item.introduction,
            value2: item.content,
            image: JSON.parse(item.images),
          };
        })
        this.data = showData;
      }
    })
  },
});
