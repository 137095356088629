import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "block-train" }

export function render(_ctx, _cache) {
  const _component_MiniTitle = _resolveComponent("MiniTitle")
  const _component_TopOne = _resolveComponent("TopOne")
  const _component_TopTwo = _resolveComponent("TopTwo")
  const _component_TopThree = _resolveComponent("TopThree")
  const _component_item = _resolveComponent("item")

  return (_openBlock(), _createBlock(_component_item, { title: "国际班列" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MiniTitle, { title: "国际班列信息" }),
        (_ctx.data.length)
          ? (_openBlock(), _createBlock(_component_TopOne, {
              key: 0,
              data: _ctx.data,
              onChange: _ctx.handleTabChange
            }, null, 8, ["data", "onChange"]))
          : _createCommentVNode("", true),
        _createVNode(_component_MiniTitle, {
          title: "操作量",
          style: {"margin-top":"60px"}
        }),
        (_ctx.hcData.data)
          ? (_openBlock(), _createBlock(_component_TopTwo, {
              key: 1,
              data: _ctx.hcData
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_MiniTitle, {
          title: "装卸火车",
          style: {"margin-top":"60px"}
        }),
        (_ctx.cData)
          ? (_openBlock(), _createBlock(_component_TopThree, {
              key: 2,
              data: _ctx.cData
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}